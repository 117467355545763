/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets

 Dark Blue: #6987c9
 Light Blue: #6bbaec
 Navy Grey: #BCC4DB
 Body Font: Lato

 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url('https://unpkg.com/bootstrap@3.3.7/dist/css/bootstrap.min.css');

@import "../node_modules/angular-calendar/css/angular-calendar.css";

body, span, p {
    color: #373D3F;
}
ion-button {
    width: calc(100% - 20px);
    border-radius: 5px;
    height: 70px;
    font-weight: bold;
    font-size: 20px;
    float: left;
    margin-left: -10px;
    --background: var(--ion-color-primary);
}
fa-icon.custom {
    // fill: black;
    stroke: black;
    fill-opacity: 0;
    stroke-opacity: 0.9;
    stroke-width: 20;
}
.box-shadow {
    box-shadow: var(--ion-color-primary) 0px 2px 8px 0px;
}
.gauge {
    height: 300px;
    margin-bottom: -50px;
}
.text-center {
    text-align: center;
}
.grid-center {
    display: grid;
    align-items: center;
}
.hidden {
    display: none;
}
.list-count {
    text-align: center;
    margin: 20px 0 15px 0;
    font-size: 16px;
}
:root {
    .toolbar-background {
        --ion-background-color: var(--ion-color-primary);
    }
}
ion-searchbar {
    padding: 20px 15px 0 !important;
    text-align: center;
}
ion-searchbar.bottom {
    margin-bottom: 20px !important;
}
ion-searchbar {
    .searchbar-input-container {
        input{
            border-radius: 5px !important;
            padding: 15px !important;
            margin: 5px !important;
            width: calc(100% - 10px) !important;
            box-shadow: 0px 1px 5px 2px var(--ion-color-primary) !important;
        }
    }
}
.searchbar-search-icon.sc-ion-searchbar-md { 
    display:none; 
    width:0; 
    height:0; 
}
ion-toggle {
    --background: #ccc;
    --background-checked: var(--ion-color-primary);
    --handle-background: #fff;
    --handle-background-checked: #fff;
    --border-radius: 50px;
    --handle-height: 30px;
    --handle-width: 30px;
    --handle-border-radius: 50px;
    width: 70px;
    height: 30px;
}
label {
    display: block; 
}
.save {
    position: absolute;
    right: 20px;
    top: 15px;
    color: #008000;
}
.back {
    position: absolute;
    right: 20px;
    top: 15px;
    font-size: 18px;
    cursor: pointer;
}
.uploads {
    margin-top: 20px;
}
.upload {
    text-align: center;
    border: 1px dotted var(--ion-color-primary);
    border-radius: 5px;
    padding: 20px;
    color: var(--ion-color-primary);
    margin-top: 20px;
}
.upload input {
    opacity: 0;
    position: absolute;
    height: 60px;
}
.uploads .file {
    align-items: end;
}
.uploads img,
.uploads fa-icon {
    max-height: 100px;
    margin: auto;
}
.uploads input {
    margin-top: 10px;
    border: none;
    text-align: center;
}

.skinny .card {
    margin: 0 -5px 10px -5px !important;
}
.short .card {
    min-height: 75px !important;
}

.green-border .card {
    border-color: green !important;
}

.loading .sc-ion-loading-md {
    background: white;
    --backdrop-opacity: 0.95;
    border-radius: 5px;
}

.bottom-button,
.green-button,
.blue-button {
    text-align: center;
}
.bottom-button ion-button {
    margin: auto;
    float: unset !important;
    width: 100%;
    height: 50px;
    margin-top: 15px;
}
.green-button ion-button {
    width: 100%;
    height: 50px;
    margin: 15px 0 0;
    --background: #008000;
}
.blue-button ion-button {
    width: 100%;
    height: 50px;
    margin: 15px 0 0;
    --background: #0271aa !important;
}

.header-translucent-ios ion-toolbar ion-title {
    padding: 0 15px !important;
}

/* Scheduler */
.cal-all-day-events {
    min-height: 200px;
    position: fixed !important;
    width: 100%;
    z-index: 3;
    background: white;
    top: 55px;
} 
.cal-time-events {
    margin-top: 210px;
}
.cal-week-view .cal-all-day-events {
    margin-top: 65px;
    border-top: 1px solid #ccc;
    padding-top: 5px;
    overflow: scroll;
    max-height: 200px;
    padding-left: 60px;
    overflow-x: hidden;
}
.cal-week-view .cal-all-day-events .cal-event {
    margin-right: 10px;
    margin-left: -60px;
    border-radius: 5px;
}

.cal-event-title {
    color: white;
}
.cal-week-view mwl-calendar-week-view-hour-segment, 
.cal-week-view .cal-hour-segment {
    // height: 50px !important;
}
.cal-week-view .cal-time-events .cal-event-container {
    min-height: 60px !important;
}
.cal-week-view .cal-time-events .cal-day-column {
    margin-top: 60px !important;
}

mwl-calendar-week-view-header {
    position: absolute;
    margin-top: 140px;
}
.yb-week .cal-time-events {
    margin-top: 195px;
}
.cal-week-view .cal-day-headers {
    width: 100vw;
}
.current-time-marker,
.cal-current-time-marker {
    // display: none;
}
ion-title {
    padding-right: 65px;
}

.filters .card {
    margin: 0 -10px 10px !important;
}

.scan-hover {
    z-index: 999;
}

@media only screen and (min-width: 999px) {
    ion-menu {
      max-width: 200px;
    }
    .scan-hover {
        display: none !important;
    }
    ion-searchbar {
        min-height: 80px;
        --background: white !important;
    }
}

.ios ion-searchbar {
    min-height: 80px;
    --background: white !important;
    padding: 20px !important;
    input {
        padding: 10px !important;
    }
}
.searchbar-search-icon.sc-ion-searchbar-ios {
    padding: 5px 10px;
}
.sc-ion-searchbar-ios.searchbar-clear-icon {
    margin: 5px;
}
.ios .back {
    top: 6px !important;
}
.ios .save {
    top: 7px !important;
    font-size: 10px !important;
}
.ios .back.padded {
    right: 80px !important;
    padding: 0 5px !important;
    margin-top: 1px !important;
}